import { css } from 'lit';

export const styles = css`
  .playerBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  .stampyt-gallery {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
  }

  .imageContainer {
    position: relative;
    height: 100%;
  }

  .bright {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    pointer-events: none;
  }

  .image {
    height: inherit;
    display: block;
    cursor: pointer;
    object-fit: contain;
    font-family: "object-fit: contain;";
    max-width: none;
    -ms-interpolation-mode: bicubic;
  }

  .leftBottomThumbnail,
  .rightBottomThumbnail {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 25px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .leftBottomThumbnail:hover,
  .rightBottomThumbnail:hover {
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .leftBottomThumbnail {
    left: 0px;
  }

  .rightBottomThumbnail {
    right: 0px;
  }

  .leftBottomThumbnail svg,
  .rightBottomThumbnail svg {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 50%;
    left: 0px;
    margin-top: -50%;
  }
`;
