import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { playerStyles } from './styles';

export class PlayerStartscreen extends LitElement {
  static styles = [
    playerStyles,
  ];

  @property() startScreen: string = 'none';
  @property() infoBackgroundColor: string = '';

  firstUpdated() {
    const pictoBandeau = this.renderRoot.querySelector('.stampyt-bandeau') as HTMLElement;
    const picto360 = this.renderRoot.querySelector('.stampyt-picto') as HTMLElement;

    const cls1 = this.renderRoot.querySelectorAll('.cls-1') as NodeListOf<HTMLElement>;
    const st1 = this.renderRoot.querySelector('.st1') as HTMLElement;

    if (this.infoBackgroundColor !== '') {
      cls1.forEach((el) => {
        el.style.fill = this.infoBackgroundColor;
        el.style.stroke = this.infoBackgroundColor;
      });
      st1.style.fill = this.infoBackgroundColor;
    }

    switch (this.startScreen) {
      case 'none':
        break;
      case '360':
        picto360.setAttribute(
          'class',
          `${picto360.getAttribute('class')} visible`,
        );
        break;
      case 'welcome':
        pictoBandeau.setAttribute(
          'class',
          `${pictoBandeau.getAttribute('class')} visible`,
        );
        break;
      default:
        break;
    }
  }

  render() {
    return html`
        <svg class="stampyt-picto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
            <g class="st0" opacity=".65">
                <circle class="st1" cx="150" cy="150" r="147" />
                <path class="st2"
                      d="M150 4c39 0 75.7 15.2 103.2 42.8C280.8 74.3 296 111 296 150s-15.2 75.7-42.8 103.2C225.7 280.8 189 296 150 296s-75.7-15.2-103.2-42.8C19.2 225.7 4 189 4 150S19.2 74.3 46.8 46.8C74.3 19.2 111 4 150 4m0-2C68.3 2 2 68.3 2 150s66.3 148 148 148 148-66.3 148-148S231.7 2 150 2z"
                      fill="#414042" />
            </g>
            <path class="st3" d="M62.9 88.2C78.4 60.9 111.6 42 150 42c50.1 0 91.3 32 96 73" fill="none" stroke="#fff"
                  stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path class="st4" fill="#fff" d="M54 115.1l28.7-32-18.5 3.4-12.1-14.4z" />
            <path class="st3" d="M237.1 216.7c-15.5 27.3-48.7 46.2-87.1 46.2-50.1 0-91.3-32-96-73" fill="none"
                  stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path class="st4" fill="#fff" d="M246 189.8l-28.7 32 18.5-3.4 12.1 14.4z" />
            <g>
                <path class="st4"
                      d="M106.8 138.4c0 6.2-3.7 10.4-10.1 12.3 6.4.7 11.7 4.5 11.7 12.7 0 8.6-6.9 15.9-19.7 15.9-7.8 0-13.6-2.9-17.7-7.8l6.5-6.3c3.4 3.4 6.5 5 10.5 5 4.7 0 8-2.7 8-7.5 0-5.4-3-7.4-8.4-7.4h-4.3l1.3-8.3h3c4.6 0 7.4-2.4 7.4-6.7 0-3.7-2.7-5.9-6.9-5.9-3.8 0-7 1.5-10.1 4.5l-5.9-6.5c4.8-4.6 10.5-6.9 17.4-6.9 11.3-.2 17.3 5.7 17.3 12.9zM152.2 160.3c0 10.5-7.2 18.9-18.7 18.9-13.6 0-19.5-10.1-19.5-24.8 0-17.5 8.5-29 22.4-29 4.9 0 8.8 1.4 11.7 3.4l-4.4 7.2c-2.1-1.2-4.3-2-7-2-5.8 0-9.5 5-10.2 14.1 3.2-3.4 7-4.8 10.9-4.8 8.4 0 14.8 6.1 14.8 17zm-12.1.2c0-6.6-2.1-8.9-6.1-8.9-2.7 0-5.3 1.4-7.5 4 .2 10.7 2 15 7.2 15 4.3 0 6.4-4 6.4-10.1zM195.8 152.2c0 17.2-6.8 27-19.4 27-12.7 0-19.4-9.8-19.4-27 0-17.3 6.8-26.9 19.4-26.9 12.7 0 19.4 9.6 19.4 26.9zm-26.5 0c0 13.6 2.1 18.1 7.1 18.1s7.1-4.1 7.1-18.1c0-14-2.1-18-7.1-18-5 .1-7.1 4.4-7.1 18zM201.6 136.7c0-8.2 6.9-12.9 13.6-12.9s13.6 4.7 13.6 12.9-6.9 13.1-13.6 13.1-13.6-4.9-13.6-13.1zm18.9 0c0-3.7-2.1-5.9-5.3-5.9-3.2 0-5.3 2.3-5.3 5.9 0 3.7 2.1 6 5.3 6 3.3 0 5.3-2.3 5.3-6z"
                      fill="#fff" />
            </g>
        </svg>

        <svg class="stampyt-bandeau" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 960">
            <defs>
                <style>
                    .cls-2,
                    .cls-3,
                    .cls-4 {
                        fill: #fff
                    }

                    .cls-2 {
                        opacity: .2
                    }

                    .cls-3 {
                        opacity: .7
                    }

                    .cls-8 {
                        letter-spacing: -.01em
                    }

                    .cls-9 {
                        letter-spacing: 0
                    }

                    .cls-10 {
                        letter-spacing: -.01em
                    }
                </style>
            </defs>
            <path class="cls-1" d="M389.41 380.02h501.18v157.79H389.41z" />
            <circle class="cls-2" cx="575.01" cy="379.88" r="55.15" />
            <circle class="cls-3" cx="575.01" cy="379.88" r="42.31" />
            <circle class="cls-4" cx="575.01" cy="379.88" r="33.26" />
            <path class="cls-1"
                  d="M587.05 406.24l-2.86-10.52h6.35a1.29 1.29 0 0 0 1.16-.74 1.27 1.27 0 0 0-.15-1.36l-16.44-20.67a1.29 1.29 0 0 0-2.3.62l-3.68 26.16a1.3 1.3 0 0 0 1.94 1.3l5.48-3.2 2.87 10.52a1.31 1.31 0 0 0 1.59.91l5.13-1.4a1.3 1.3 0 0 0 .91-1.59zm0 0" />
            <path class="cls-1" d="M562.39 366.61h25.23" fill="none" stroke="#32abf1" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2.76" />
            <path class="cls-1"
                  d="M557.39 366.61a25.41 25.41 0 0 1 7.09 3.95l-1.43-3.95 1.43-3.94a25.14 25.14 0 0 1-7.09 3.94zM592.63 366.61a25.27 25.27 0 0 0-7.09 3.95l1.43-3.95-1.43-3.94a25 25 0 0 0 7.09 3.94z" />
            <circle class="cls-2" cx="697.9" cy="379.88" r="55.15" />
            <circle class="cls-3" cx="697.9" cy="379.88" r="42.31" />
            <circle class="cls-4" cx="697.9" cy="379.88" r="33.26" />
            <path class="cls-1"
                  d="M701.75 398.05a9.81 9.81 0 0 0-1-.6c-.3-.18-.61-.36-.89-.55a19.22 19.22 0 0 0-2.14-1.24h-16.53v-32.28h22.83v15.16l.88 1.13c.35.45.72.89 1.06 1.35.32-.09.69-.17 1.1-.26v-18.57a3.39 3.39 0 0 0-2-3.12 3.19 3.19 0 0 0-1.28-.27h-22.34a3.26 3.26 0 0 0-1.28.27 3.39 3.39 0 0 0-2 3.13v35.36a3.34 3.34 0 0 0 3.28 3.39h22.5a3.11 3.11 0 0 0 2.41-1.15c-.74-.23-1.25-.34-1.25-.35a8.1 8.1 0 0 1-3.34-1.39zm-6.79.66a.85.85 0 0 1-.76.46H691a.88.88 0 0 1-.71-1.35.48.48 0 0 1 .12-.16.83.83 0 0 1 .6-.26h3.17a.87.87 0 0 1 .76.46 1 1 0 0 1 .1.43 1.08 1.08 0 0 1-.1.42zm0 0" />
            <path class="cls-1"
                  d="M721.05 394.2l-.64-1.14a18.53 18.53 0 0 1-.73-2 25.53 25.53 0 0 0-2.79-7.69 1 1 0 0 0-.74-.59 20.5 20.5 0 0 0-10.92.59l-.87-1.09c-.68-.92-1.42-1.78-2.11-2.71s-1.5-2-2.28-2.93a1.6 1.6 0 0 0-2.15-.36 1.53 1.53 0 0 0-.41 2.11c2 3.57 4 7.09 5.94 10.65a4.06 4.06 0 0 1 .41 1.29c.09.91 0 1.69-.91 1.37s-3.21-1.46-3.71-1.51a4 4 0 0 0-2.51.23 2.17 2.17 0 0 0-.91 1c-.28.74-.09 1.19.63 1.42a20.18 20.18 0 0 1 4.67 2.33c.55.36 1.37.78 1.92 1.18a6.32 6.32 0 0 0 2.56 1.06 18.06 18.06 0 0 1 6.55 3 2.6 2.6 0 0 0 3.2 0l5.54-4.53a1.32 1.32 0 0 0 .26-1.65zm0 0" />
            <text transform="translate(442.04 467.27)" font-size="24" font-family="FiraSans-Light,Fira Sans"
                  fill="#fff">Déplacez<tspan x="78.02" y="0" letter-spacing="-2em"></tspan>
                <tspan x="108.5" y="0">vous </tspan>
                <tspan x="170.64" y="0">autour du </tspan>
                <tspan class="cls-8" x="290.52" y="0">v</tspan>
                <tspan x="301.56" y="0">éhicu</tspan>
                <tspan class="cls-10" x="360.12" y="0">l</tspan>
                <tspan class="cls-9" x="366.69" y="0">e</tspan>
                <tspan font-size="38" font-family="FiraSans-Regular,Fira Sans">
                    <tspan x="-15.73" y="42">VIVEZ</tspan>
                    <tspan x="77.9" y="42" letter-spacing="0" font-family="FiraSans-Bold,Fira Sans" font-weight="700">
                    </tspan>
                    <tspan x="86.91" y="42" letter-spacing="-.07em" font-family="FiraSans-Bold,Fira Sans"
                           font-weight="700">L</tspan>
                    <tspan x="103.74" y="42" font-family="FiraSans-Bold,Fira Sans" font-weight="700">’EXPÉRIENCE 360°
                    </tspan>
                </tspan>
            </text>
        </svg>
    `;
  }
}

window.customElements.define('player-startscreen', PlayerStartscreen);
