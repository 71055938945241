import { IHotspot } from './hotspot';

enum ImageType { original, stamped }

interface IImage {
  type: ImageType;
  url: string;
  urlHD?: string;
  urlLow?: string;
  order: number;
  hotspots: IHotspot[];
}

class Image implements IImage {
  type: ImageType;
  url: string;
  urlHD?: string;
  urlLow?: string;
  order: number;
  hotspots: IHotspot[];

  constructor(
      type: ImageType,
      url: string,
      order: number,
      hotspots: IHotspot[],
      urlHD?: string,
      urlLow?: string,
  ) {
    this.type = type;
    this.url = url;
    this.urlHD = urlHD;
    this.urlLow = urlLow;
    this.order = order;
    this.hotspots = hotspots;
  }
}

export { IImage, ImageType, Image };
