import { IImage, ImageType, Image } from './image';
import { IShootingPhotos } from './shootingPhotos';

interface IInspectRepairOrder  extends IShootingPhotos{}

class InspectRepairOrder implements IInspectRepairOrder {
  images: IImage[] = [];

  build(shooting: any): IInspectRepairOrder {
    if (!shooting) return {} as IShootingPhotos;

    try {
      const originals = shooting.images.map((el:any) =>
          InspectRepairOrder.buildImage(el, ImageType.original));

      return {
        images: [...originals],
      } as IInspectRepairOrder;
    } catch (e) {
      return {} as IShootingPhotos;
    }
  }

  private static buildImage(el: any, imageType: ImageType) {
    return new Image(
        imageType,
        el.url,
        el.order,
        el.hotspots,
    );
  }
}

export { InspectRepairOrder };
