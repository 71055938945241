import { css } from 'lit';

export const playerStyles = css`
    .loader-container {
        position: absolute;
        z-index: 101010101;
        width: 100%;
        height: 100%;
        display: none;
    }

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75px;
        height: 75px;
        margin-top: -37.5px;
        margin-left: -37.5px;
        background-color: rgba(86, 86, 86, 0.75);
        color: #FFFFFF;
        font-family: Verdana;
        font-weight: bold;
        font-size: 21px;
        text-align: center;
        line-height: 75px;
        white-space: nowrap;
        border-radius: 9px;
    }

    .active {
        display: block;
}
`;
