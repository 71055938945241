interface IImageLoadingInfo{
  concurrency: number;
  start: number;
  end: number;
  iterations: number;
  imagesContainer: HTMLImageElement[];
}

function addAllImagesToDOM(imageElements: HTMLImageElement[]) {
  const body = document.querySelector('body');

  if (!body) return;

  const imagesWrapper = document.createElement('div') as HTMLDivElement;
  imagesWrapper.setAttribute('class', `prefetch-image-wrapper_${Math.random()}`);
  imagesWrapper.style.width = '0';
  imagesWrapper.style.height = '0';
  imagesWrapper.style.overflow = 'hidden';
  imagesWrapper.style.display = 'none';
  imageElements.forEach((img) => {
    imagesWrapper.appendChild(img);
  });
  body.appendChild(imagesWrapper);
}

function loadImage(url: string, container: HTMLImageElement[]) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(url);
    img.onerror = () => resolve(url);
    img.src = url;
    container.push(img);
  });
}

function loadImages(
    imageUrls: string[],
    imageLoadingInfo: IImageLoadingInfo) {
  const allImageLength = imageUrls.length;
  const info = imageLoadingInfo;

  if (info.start >= allImageLength) {
    return Promise.resolve([]);
  }

  const start = info.start;
  const end = start + info.concurrency;

  const imagePromises = imageUrls.slice(start, end).map((url) => {
    loadImage(url, info.imagesContainer);
  });

  info.start = end;
  info.end = end + info.concurrency;

  return Promise.all(imagePromises);
}

export function prefetchImages(imageUrls: string[]): Promise<HTMLImageElement[]> {
  if (!imageUrls) {
    console.error('[prefetch-image]: images not provided, pls pass images in Array or object!');
    return Promise.reject({});
  }

  const concurrency = 6;
  const imageLoadingInfo: IImageLoadingInfo = {
    concurrency,
    start: 0,
    end: 0,
    iterations: Math.ceil(imageUrls.length / concurrency),
    imagesContainer: [],
  };

  const bulkImagePromises = [...Array(imageLoadingInfo.iterations)].map(() => {
    loadImages(imageUrls, imageLoadingInfo);
  });

  return Promise.all(bulkImagePromises).then(() => {
    addAllImagesToDOM(imageLoadingInfo.imagesContainer);
    return Promise.resolve(imageLoadingInfo.imagesContainer);
  }).catch((err) => {
    console.error('[prefetch-image]: ', err);
    return Promise.reject(imageLoadingInfo.imagesContainer);
  });
}
