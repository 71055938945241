export default {
  panoramaOutsideCloseLabel: 'Panorama esterno a porte chiuse',
  panoramaOutsideOpenLabel: 'Panorama all\'aperto Open Porte',
  panoramaInsideLabel: 'Panorama interiore',
  videoLabel: 'Video',
  certificateButtonLabel: 'Certificato di protezione di Stampyt',
  constructPlayer: 'Il giocatore è in costruzione',
  oups: 'Whoops',
  error: 'Ci scusiamo, si é verificato un errore',
};
