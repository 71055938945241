import { IShooting360, Shooting360 } from './shooting360';
import { IShootingPhotos, ShootingPhotos } from './shootingPhotos';
import { IPanoramaInside, PanoramaInside } from './panoramaInside';
import { IVideo, Video } from './video';

interface IShootings {
  panoramaOutsideClose: IShooting360;
  panoramaOutsideOpen: IShooting360;
  standard: IShootingPhotos;
  panoramaInside: IPanoramaInside;
  video: IVideo;
}

class Shootings implements IShootings {
  panoramaOutsideClose: IShooting360 = new Shooting360();
  panoramaOutsideOpen: IShooting360 = new Shooting360();
  standard: IShootingPhotos = new ShootingPhotos();
  panoramaInside: IPanoramaInside = new PanoramaInside();
  video: IVideo = new Video();

  static buildFromPanoramaJson = (data: any): IShootings => {
    try {
      const findShooting = (arr: any, name: string) => arr.find((s: any) => s.type === name);

      const panoramaOutsideClose = findShooting(data, 'PANORAMA_OUTSIDE_CLOSE');
      const panoramaOutsideCloseHd = findShooting(data, 'PANORAMA_OUTSIDE_CLOSE_HD');
      const panoramaOutsideOpen = findShooting(data, 'PANORAMA_OUTSIDE_OPEN');
      const panoramaOutsideOpenHd = findShooting(data, 'PANORAMA_OUTSIDE_OPEN_HD');
      const panoramaInside = findShooting(data, 'PANORAMA_INSIDE');
      const video = findShooting(data, 'VIDEO');
      const standard = findShooting(data, 'STANDARD');
      const inspectionRepairOrder = findShooting(data, 'INSPECTION_REPAIR_ORDER');
      const inspection = findShooting(data, 'INSPECTION');

      const shootings : IShootings =
        {
          panoramaOutsideClose: new Shooting360().build(
                panoramaOutsideClose,
                panoramaOutsideCloseHd,
            ),
          panoramaOutsideOpen: new Shooting360().build(panoramaOutsideOpen, panoramaOutsideOpenHd),
          standard: ShootingPhotos.buildStandardIfExist(
              standard,
              inspection,
              inspectionRepairOrder,
          ),
          panoramaInside: new PanoramaInside().build(panoramaInside),
          video: Video.buildVideoIfExist(video, inspectionRepairOrder),
        };

      return shootings;
    } catch (err) {
      return new Shootings();
    }
  }

  static buildFromExternalImages = (images: any): IShootings => {
    let objectImages: any[] = [];

    try {
      objectImages = JSON.parse(images);
    } catch (e) {
      console.log('externalImages is not a correct JSON');
    }

    const jsonShootings = objectImages.map((objectImage) => {
      const object = objectImage;
      const shootingsTypes = ['STANDARD', 'PANORAMA_INSIDE', 'PANORAMA_OUTSIDE_OPEN', 'PANORAMA_OUTSIDE_CLOSE'];
      const hasCorrectType = shootingsTypes.includes(object.type);
      const hasImages = object.images && object.images.length > 0;

      if (!hasCorrectType || !hasImages) return {};

      const originals: any[] = [];
      const stampeds: any[] = [];

      object.images.forEach((url: string, j: number) => {
        originals[j] = {
          url,
          order: j,
          hotspots: [],
        };
        stampeds[j] = {
          url,
          order: j,
          hotspots: [],
        };
      });

      return { originals, stampeds, type: object.type };

    });

    return Shootings.buildFromPanoramaJson(jsonShootings);
  }
}

export { IShootings, Shootings };
