import axios from 'axios';
import { IShootings, Shootings } from '../models/shootings';

export async function getPanorama(
    reference: string,
    token: string,
    dms: string,
    imageView: string,
): Promise<IShootings> {
  function createUrl(reference: string, dms: string, imageView: string) {
    let url = `${process.env.STAMPYT_API_HOST}/panorama-shootings?ref=${reference}`;

    if (dms !== '') {
      url = `${url}&dms=${dms}`;
    }

    if (imageView === 'cropped' || imageView === 'notCropped') {
      url = `${url}&imageView=${imageView}`;
    }

    return url;
  }

  const url = createUrl(reference, dms, imageView);
  const headers = {
    'x-api-key': token,
  };

  try {
    const res = await axios.get(url, { headers });

    if (res.status === 200) {
      return Shootings.buildFromPanoramaJson(res.data);
    }

    return new Shootings();
  } catch (error) {
    return new Shootings();
  }
}

export async function getStreamReadyStatus(providerType: string, reference: string, token: string) {
  const url = `${process.env.STAMPYT_API_HOST}/panorama-shootings/videos/providers/${providerType}/references/${reference}/status`;

  const config = {
    headers: {
      ['x-api-key']: token,
      ['cache-control']: 'no-cache',
    },
  };

  const res = await axios.get(url, config);
  if (res.status === 200) {
    return res.data;
  }

  return null;
}

export async function getCertificateLabel(reference: string) {
  const url = `${process.env.SAAS_HOST}/API/V1/oCode/${reference}`;

  try {
    const res = await axios.get(url);

    if (res.status === 200) return res.data;

    return [];
  } catch (e) {
    return [];
  }
}
