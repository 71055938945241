export default {
  panoramaOutsideCloseLabel: 'Zamknięte drzwi panoramy na zewnątrz',
  panoramaOutsideOpenLabel: 'Otwarte drzwi panoramy na zewnątrz',
  panoramaInsideLabel: 'Wewnętrzna panorama',
  videoLabel: 'Wideo',
  certificateButtonLabel: 'Certyfikat Protect Stampyt',
  constructPlayer: 'Gracz jest w budowie',
  oups: 'Ups',
  error: 'Przepraszamy, pojawił się błąd',
};
