export default {
  panoramaOutsideCloseLabel: 'Panorama al aire libre puertas cerradas',
  panoramaOutsideOpenLabel: 'Panorama al aire libre puertas abiertas',
  panoramaInsideLabel: 'Panorama interno',
  videoLabel: 'Video',
  certificateButtonLabel: 'Certificado de Protección Stampyt',
  constructPlayer: 'El jugador está en construcción',
  oups: 'Gritos',
  error: 'Disculpe, ocurrió un error',
};
