// Zoom au scroll
export const Scroll = function () {
    let _max_pos,
        _last_pos,
        _image,
        _max_scale,
        _factor,
        _divWheel,
        _isScrolled,
        _size,
        _pos,
        _zoom_target,
        _zoom_point,
        _scale,
        _last_scale;

    this.init = function (img, max_scale, factor, divWheel, isScrolled) {
        _image = img;
        _max_scale = max_scale;
        _factor = factor;
        _divWheel = divWheel;
        _isScrolled = isScrolled;

        _size = {
            w: _image.offsetWidth,
            h: _image.offsetHeight,
        };
        _max_pos = {
            x: 0,
            y: 0,
        };
        _pos = {
            x: 0,
            y: 0,
        };
        _zoom_target = {
            x: 0,
            y: 0,
        };
        _zoom_point = {
            x: 0,
            y: 0,
        };
        _last_pos = {
            x: 0,
            y: 0,
        };

        _last_scale = 1;
        _scale = 1;

        img.style.transformOrigin = "0 0";

        divWheel.addEventListener("mousewheel", this.scrolled, { passive: false });
        divWheel.addEventListener("DOMMouseScroll", this.scrolled, {
            passive: false,
        });
        window.addEventListener("resize", function () {
            _size = {
                w: _image.offsetWidth,
                h: _image.offsetHeight,
            };
        });
    };

    this.scrolled = function (e) {
        e.preventDefault();

        const xMarging = (_divWheel.offsetWidth - _size.w) / 2;
        const yMarging = (_divWheel.offsetHeight - _size.h) / 2;

        _zoom_point.x = e.offsetX - xMarging;
        _zoom_point.y = e.offsetY - yMarging;

        let delta = e.delta || e.wheelDelta;

        if (delta === undefined) {
            //we are on firefox
            delta = e.detail;
        }

        delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

        // determine the point on where the slide is zoomed in
        _zoom_target.x = (_zoom_point.x - _pos.x) / _scale;
        _zoom_target.y = (_zoom_point.y - _pos.y) / _scale;

        // apply zoom
        _scale += delta * _factor * _scale;
        _scale = Math.max(1, Math.min(_max_scale, _scale));

        // calculate x and y based on zoom
        _pos.x = -_zoom_target.x * _scale + _zoom_point.x;
        _pos.y = -_zoom_target.y * _scale + _zoom_point.y;

        _last_pos.x = _pos.x;
        _last_pos.y = _pos.y;

        _isScrolled(_scale);

        insideFrame();
    };

    this.pinch = function (e) {
        //pinch

        const xMarging = (_divWheel.offsetWidth - _size.w) / 2;
        const yMarging = (_divWheel.offsetHeight - _size.h) / 2;

        if (e.type === "pinch") {
            let delta = e.additionalEvent === "pinchout" ? 1 : -1;

            _zoom_point.x = e.srcEvent.pageX - xMarging;
            _zoom_point.y = e.srcEvent.pageY - yMarging;

            // determine the point on where the slide is zoomed in
            _zoom_target.x = (_zoom_point.x - _pos.x) / _scale;
            _zoom_target.y = (_zoom_point.y - _pos.y) / _scale;

            _scale += _factor * _scale * delta;
            _scale = Math.max(1, Math.min(_max_scale, _scale));
            if (_scale === 10) return;

            // calculate x and y based on zoom
            _pos.x = -_zoom_target.x * _scale + _zoom_point.x;
            _pos.y = -_zoom_target.y * _scale + _zoom_point.y;

            _last_pos.x = _pos.x;
            _last_pos.y = _pos.y;

            _isScrolled(_scale);

            insideFrame();
        }
    };

    // Bouge l'image
    this.moveImage = function (e) {
        //pan
        if (e.type === "pan") {
            if (_scale !== 1) {
                _zoom_target.x = (_zoom_point.x - _pos.x) / _scale;
                _zoom_target.y = (_zoom_point.y - _pos.y) / _scale;

                _pos.x = _last_pos.x + e.deltaX;
                _pos.y = _last_pos.y + e.deltaY;

                _max_pos.x = Math.ceil(((_scale - 1) * _image.clientWidth) / 2);
                _max_pos.y = Math.ceil(((_scale - 1) * _image.clientHeight) / 2);

                insideFrame();
            }
        }

        //panend
        if (e.type === "panend") {
            _last_pos.x = _pos.x < _max_pos.x ? _pos.x : _max_pos.x;
            _last_pos.y = _pos.y < _max_pos.y ? _pos.y : _max_pos.y;
        }
    };

    this.reset = function () {
        if (_image) {
            _image.style.transform = "";
        }

        _size = {
            w: _image.offsetWidth,
            h: _image.offsetHeight,
        };

        _max_pos = {
            x: 0,
            y: 0,
        };
        _pos = {
            x: 0,
            y: 0,
        };
        _zoom_target = {
            x: 0,
            y: 0,
        };
        _zoom_point = {
            x: 0,
            y: 0,
        };
        _last_pos = {
            x: 0,
            y: 0,
        };

        _scale = 1;
    };

    // garde l'image a l'interieur d'une frame
    function insideFrame() {
        if (_pos.x > 0) _pos.x = 0;
        if (_pos.x + _size.w * _scale < _size.w) _pos.x = -_size.w * (_scale - 1);
        if (_pos.y > 0) _pos.y = 0;
        if (_pos.y + _size.h * _scale < _size.h) _pos.y = -_size.h * (_scale - 1);

        updatePosition();
    }

    // update la position de l'image
    function updatePosition() {
        if (_scale === 1) {
            _image.style.transform = "none";
        } else {
            _image.style.transform =
                "translate3d(" +
                _pos.x +
                "px," +
                _pos.y +
                "px," +
                0 +
                "px) " +
                "scale3d(" +
                _scale +
                ", " +
                _scale +
                ", " +
                _scale +
                ")";
        }
    }
};