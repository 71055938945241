import { IHotspot } from './hotspot';

interface IPanoramaInside {
  url: string;
  hotspots: IHotspot[];
}

class PanoramaInside implements IPanoramaInside {
  url: string = '';
  hotspots: IHotspot[] = [];

  build(shooting: any): IPanoramaInside {
    if (!shooting) return new PanoramaInside();

    try {
      return {
        url: shooting.originals[0].url,
        hotspots: shooting.originals[0].hotspots,
      } as IPanoramaInside;
    } catch (e) {
      return new PanoramaInside();
    }
  }
}

export { IPanoramaInside, PanoramaInside };
