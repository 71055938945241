import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { translate } from 'lit-translate';

@customElement('player-empty')
export class PlayerEmpty extends LitElement {
  static get styles() {
    return css`
        .construct-container {
          position: relative;
          width: 99%;
          height: 99%;
          border: 1px solid rgb(216, 216, 216);
        }
        .title {
          font-family: "Fira Sans";
          font-weight: 400;
          font-size: 24px;
        }
        .text {
          font-family: "Fira Sans";
          font-weight: 300;
          font-size: 18px;
        }
        .center-text {
          text-align: center;
        }
        .center {
          position: absolute;
          width: 100%;
          max-width: 250px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
    `;
  }

  render() {
    return html`
            <div class="construct-container">
                <div class="font center center-text">
                    <div>
                        <svg
                                width="110"
                                height="110"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="55" cy="55" r="55" fill="#CDCDCD" fill-opacity=".3" />
                            <path
                                    d="M55 62.286a7.286 7.286 0 100-14.572 7.286 7.286 0 000 14.572z"
                                    stroke="#4F4F4F"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                            <path
                                    d="M72.972 62.286a4.008 4.008 0 00.8 4.42l.147.145a4.856 4.856 0 010 6.873 4.857 4.857 0 01-6.873 0l-.146-.145a4.008 4.008 0 00-4.42-.802 4.007 4.007 0 00-2.428 3.667v.413a4.857 4.857 0 01-9.715 0v-.219a4.007 4.007 0 00-2.623-3.667 4.006 4.006 0 00-4.42.802l-.145.145a4.857 4.857 0 01-7.928-1.576 4.856 4.856 0 011.055-5.296l.145-.146a4.007 4.007 0 00.802-4.42 4.007 4.007 0 00-3.667-2.429h-.413a4.857 4.857 0 010-9.714h.218a4.007 4.007 0 003.668-2.623 4.007 4.007 0 00-.802-4.42l-.145-.145a4.857 4.857 0 011.576-7.928 4.856 4.856 0 015.296 1.055l.146.145a4.008 4.008 0 004.42.802h.194a4.007 4.007 0 002.429-3.667v-.413a4.857 4.857 0 119.714 0v.218a4.007 4.007 0 002.429 3.668 4.007 4.007 0 004.42-.802l.145-.146a4.857 4.857 0 017.928 1.577 4.856 4.856 0 01-1.055 5.296l-.145.146a4.008 4.008 0 00-.802 4.42v.194a4.007 4.007 0 003.667 2.429h.413a4.857 4.857 0 110 9.714h-.218a4.007 4.007 0 00-3.667 2.429z"
                                    stroke="#4F4F4F"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <h1 class="title">${translate('oups')}</h1>
                    <p class="text">${translate('constructPlayer')}</p>
                </div>
    `;
  }
}
