interface IVideo {
  name: string;
  url: string;
  partner: string;
  reference: string;
  markers: any[];
}

class Video implements IVideo {
  name: string = '';
  url: string = '';
  partner: string = '';
  reference: string = '';
  markers: any[] = [];

  static buildVideoIfExist(video: any, inspectRepairOrder: any) {
    if (video) return new Video().build(video);
    if (inspectRepairOrder && inspectRepairOrder.video) {
      return new Video().build(inspectRepairOrder.video);
    }

    return new Video();
  }

  build(shooting: any): IVideo {
    if (!shooting) return new Video();

    try {
      return {
        name: shooting.name,
        url: shooting.url,
        partner: shooting.partner,
        reference: shooting.reference,
        markers: [],
      } as IVideo;
    } catch (e) {
      return new Video();
    }
  }
}

export { IVideo, Video };
