import { css } from 'lit';

export const styles = css`
  .stampyt-buttons {
      position: absolute;
      z-index: 16;
      top: 0px;
      right: 0px;
      height: 100%;
      min-width: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      pointer-events: none;
  }

  .stampyt-button-container {
    position: relative;
  }

  .stampyt-button360 {
      position: relative;
      border-radius: 50%;
      margin: 5px;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      pointer-events: all;
  }

  @media screen and (max-width: 500px) {
      .stampyt-button360,
      .stampyt-button360 {
          width: 40px;
          height: 40px;
      }
  }

  .buttonCertificateLabel {
      outline: none;
      display: flex;
      align-items: center;
      z-index: 999;
      position: absolute;
      padding: 5px 10px;
      border: none;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .buttonCertificateLabel svg {
      margin-right: 10px;
      fill: white;
  }

  #button360Closed:hover  .button-360-label,
  #button360Open:hover  .button-360-label,
  #button360In:hover  .button-360-label,
  #buttonVideoStampyt:hover  .button-360-label {
      width: 230px;
      opacity: 1;
  }

  .button-360-label {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      z-index: 16;
      width: 50px;
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      right: 70px;
      background-color: var(--button360-color-checked, #00aff0);
      color: white;
      padding: 8px;
      border-radius: 6px;
      text-align: center;
      font-size: 12px;
      cursor: pointer;
      transition: .3s width ease-in-out;
      overflow: hidden;
      white-space: nowrap;
  }

  @media only screen and (max-device-width: 823px) {
      .button-360-label {
          display: none;
      }
  }

  #buttonVideoStampyt svg,
  #buttonVideoStampyt img {
      width: var(--button-external-close-width, 100%);
      height: var(--button-external-close-height, auto);
  }

  #button360Closed svg,
  #button360Closed img {
      width: var(--button-external-close-width, 100%);
      height: var(--button-external-close-height, auto);
  }

  #button360Open svg,
  #button360Open img {
      width: var(--button-external-open-width, 100%);
      height: var(--button-external-open-height, auto);
  }

  #button360In svg,
  #button360In img {
      width: var(--button-internal-width, 100%);
      height: var(--button-internal-height, auto);
  }

  .stampyt-button-color {
      background-color: var(--button360-color, rgba(102, 102, 102, 0.8));
  }

  .stampyt-button-color.checked {
      background-color: var(--button360-color-checked, #00aff0);
  }


  .stampyt-button-color:hover {
      background-color: var(--button360-color-hover, #008abd);
  }

  #buttonFullScreen {
      display: block;
      outline: none;
      position: absolute;
      border: none;
      padding: 0;
      top: 20px;
      width: 40px;
      height: 40px;
      right: 50%;
      transform: translateX(50%);
      z-index: 999;
      cursor: pointer;
      pointer-events: all;
  }

  @media only screen and (max-device-width: 900px) {
      #buttonFullScreen {
          display: none;
      }
  }

  .inner-buttonFullScreen {
      position: relative;
      background-color: rgba(102, 102, 102, 0.8);
      transition: all .3s ease-in-out;
      width: 40px;
      height: 40px;
      border-radius: 25px;
  }

  #buttonFullScreen:hover .inner-buttonFullScreen {
      background-color: rgba(75, 72, 72, 0.8);
  }

  #buttonFullScreen svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: #fff;
      width: 20px;
      height: 20px;
  }

  @media screen and (max-width: 500px) {
      #buttonFullScreen svg {
          width: 20px;
          height: 20px;
      }
  }
`;
