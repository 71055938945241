export default {
  panoramaOutsideCloseLabel: 'Panorama ao ar livre portas fechadas',
  panoramaOutsideOpenLabel: 'Panorama ao ar livre portas abertas',
  panoramaInsideLabel: 'Panorama interno',
  videoLabel: 'Vídeo',
  certificateButtonLabel: 'Certificado de proteção do Stampyt',
  constructPlayer: 'O jogador está em construção',
  oups: 'Opa',
  error: 'Desculpe, ocorreu um erro',
};
