export default {
  panoramaOutsideCloseLabel: 'Utomhuspanorama stängda dörrar',
  panoramaOutsideOpenLabel: 'Utomhuspanorama öppna dörrar',
  panoramaInsideLabel: 'Inre panorama',
  videoLabel: 'Video',
  certificateButtonLabel: 'Stampyt Protect Certificate',
  constructPlayer: 'Spelaren är under uppbyggnad',
  oups: 'Hoppsan',
  error: 'Beklagar, ett fel uppstod',
};
