import { css } from 'lit';

export const playerStyles = css`
  .stampyt-picto {
      width: 20%;
      height: 20%;
      position: absolute;
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
  }

  .stampyt-bandeau {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
  }

  .visible {
      visibility: visible;
  }

  .cls-1 {
      fill: var(--bandeau-background-color, #32abf1);
      stroke: var(--bandeau-background-color, #32abf1);
  }

  .st1 {
      fill: var(--bandeau-background-color, #32abf1);
  }
`;
