import { css } from 'lit';

export const playerStyles = css`
  .scene {
    width: 100%;
    height: 100%;
  }

  .scene>canvas {
    cursor: grab;
  }

  .stampyt-player360 {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 0;
  }

  .player360Container {
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
  }

  .loadedImagesContainer,
  .loadedImagesLowQualityContainer,
  .loadedHDImagesContainer {
    display: none;
  }

  .loadedImagesContainer > img,
  .loadedImagesLowQualityContainer > img,
  .loadedHDImagesContainer > img {
    position: absolute;
  }

  .player360Image {
    width: 100%;
  }

  .OF-contain {
    object-fit: contain;
    font-family: 'object-fit: contain';
  }

  .notSelectable {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .visible {
    z-index: 8;
  }

  .hidden {
    display: none;
  }

  .message-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hotspot {
    max-width: 30px;
  }

  .imgFullScreen {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999;
  }

  .imgFullScreen img {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
    cursor: pointer;
  }

    .imgFullScreen > img {
    width: auto;
    height: auto;
    max-width: 85%;
    max-height: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999998;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .imgFullScreen > img::selection {
    color: none;
    background: none;
  }
`;
