import { css } from 'lit';

export const playerStyles = css`
    .cloudflare-container {
        position: relative;
        height: 100%;
    }

    .error-message-block {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(0, 0, 0);
        flex-direction: column;
    }

    .error-message {
        font-family: 'Fira-sans', sans-serif;
        color: white;
        text-align: center;
        padding: 0 10px;
        line-height: 1.4em;
        max-width: 300px;
        margin-bottom: 20px;
    }

    .button-reload-cloudflare {
        text-decoration: none;
        cursor: pointer;
        color: #fff;
        background-color: rgba(38, 166, 154, 1);
        transition: 0.2s all ease-in-out;
        text-align: center;
        letter-spacing: 0.5px;
        border: none;
        border-radius: 2px;
        height: 36px;
        line-height: 36px;
        padding: 0 16px;
    }

    .button-reload-cloudflare:hover {
        background-color: rgba(38, 166, 154, 0.9);
    }
`;
