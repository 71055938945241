import { IShootings } from '../models';
import { getPanorama } from './api';
import { Shootings } from '../models/shootings';

export async function getShootingData(
    reference: string,
    token: string,
    dms: string,
    imageView: string,
    externalImages: any,
): Promise<IShootings> {
  if (token !== '' && reference !== '') {
    return await getPanorama(reference, token, dms, imageView);
  }

  if (externalImages !== '') {
    return Shootings.buildFromExternalImages(externalImages);
  }

  return new Shootings();
}
