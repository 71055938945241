export default {
  panoramaOutsideCloseLabel: 'Buiten panorama gesloten deuren',
  panoramaOutsideOpenLabel: 'Buiten Panorama Open deuren',
  panoramaInsideLabel: 'Binnenste panorama',
  videoLabel: 'Video',
  certificateButtonLabel: 'Stampyt Protect Certificate',
  constructPlayer: 'De speler is in aanbouw',
  oups: 'Oeps',
  error: 'Sorry, er is een fout opgetreden',
};
