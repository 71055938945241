import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { getCertificateLabel } from '../../services/api';
import { buttonsColorStyle } from '../../utils/buttonsUtilities';
import { styleMap } from 'lit/directives/style-map.js';
import { playerStyles } from './styles';
import { translate } from 'lit-translate';

@customElement('player-certificate-label-button')
export class PlayerCertificateLabelButton extends LitElement {

  static styles = [
    playerStyles,
  ];

  @property() reference: string = '';
  @property() displayCertificateButton: boolean = false;
  @property() pdf: string = '';
  @property() buttonsColor: string = '';
  @property() buttonsHoverColor: string = '';
  @property() buttonCertificateLabelPx: string = '';
  @property() buttonCertificateLabelPy: string = '';
  @property() btnStyle: any = {};

  firstUpdated(changedProperties: any) {
    super.firstUpdated(changedProperties);

    this.reference !== '' &&
    getCertificateLabel(this.reference).then((res: any) => {
      if (res.length > 0) {
        this.displayCertificateButton = true;
        this.pdf = res[0].ocode_data.operation_data.media_url;

        this.setButtonPosition(
          this.buttonCertificateLabelPx,
          this.buttonCertificateLabelPy,
        );
      }
    });
  }

  protected updated(changedProperties: any) {
    super.updated(changedProperties);

    if (changedProperties.has('displayCertificateButton')) {
      if (typeof changedProperties.get('displayCertificateButton') === 'boolean') {
        const container = this.renderRoot as ShadowRoot;
        const btnColorList = [this.buttonsColor, null, this.buttonsHoverColor];
        const btn = this.renderRoot.querySelector('#buttonCertificateLabel') as HTMLElement;

        buttonsColorStyle(
            btnColorList,
            btn,
            container,
        );
      }
    }
  }

  render() {
    return html`
        ${ this.displayCertificateButton ?
          html`
              <button
                      id="buttonCertificateLabel"
                      class="stampyt-button-color buttonCertificateLabel"
                      style=${styleMap(this.btnStyle)}
                      @click="${this.downloadOCode}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
                  </svg>
                  <span>${translate('certificateButtonLabel')}</span>
              </button>
          ` : html``
        }
`;
  }

  downloadOCode() {
    if (this.pdf) {
      window.open(this.pdf);
    }
  }

  // placement du button certificat
  setButtonPosition(posX: string, posY: string) {
    this.btnStyle.left = posX !== '' ? posX : '10px';
    this.btnStyle.top = posY !== '' ? posY : '10px';
  }
}
