// Register the translate config
import { LanguageIdentifier, registerTranslateConfig } from 'lit-translate';
import fr from '../assets/lang/fr';
import en from '../assets/lang/en';
import es from '../assets/lang/es';
import de from '../assets/lang/de';
import it from '../assets/lang/it';
import nl from '../assets/lang/nl';
import pt from '../assets/lang/pt';
import pl from '../assets/lang/pl';
import sv from '../assets/lang/sv';

export function getLanguage(propsLang: string) {
  const validLanguages = ['de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'pt', 'sv'];

  if (validLanguages.includes(propsLang)) {
    return propsLang;
  }

  const navigatorLanguage = window.navigator.language.substr(0, 2);

  if (validLanguages.includes(navigatorLanguage)) {
    return navigatorLanguage;
  }

  return 'en';
}

export const initTranslation = () => {
  registerTranslateConfig({
    loader: async (lang: LanguageIdentifier) : Promise<any> => {
      switch (lang) {
        case 'en':
          return en;
        case 'es':
          return es;
        case 'de':
          return de;
        case 'it':
          return it;
        case 'nl':
          return nl;
        case 'pt':
          return pt;
        case 'pl':
          return pl;
        case 'fr':
          return fr;
        case 'sv':
          return sv;
        default:
          return en;
      }
    },
  });
};
