import './components/player360Outside/player-360-outside'
import './components/player360Inside/player-360-inside'
import './components/playerEmpty/player-empty'
import './components/playerGallery/player-gallery'
import './components/playerBottom/player-bottom'
import './components/playerLoading/player-loader'
import './components/playerVideo/player-video'
import './components/playerButtons/player-buttons'
import './components/playerCertificateLabelButton/player-certificate-label-button'

import { LitElement, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { styleMap } from 'lit/directives/style-map.js'
import { playerStyles } from './styles'
import { IImage, ImageType } from './models'
import { convertUrlSelectedImage } from './utils/cloudflare'
import { getShootingData } from './services/repository'
import { initTranslation, getLanguage } from './i18n'
import { use } from 'lit-translate'

@customElement('stampyt-360')
export class Stampyt360 extends LitElement {
  static styles = [playerStyles]

  @property() token: string = ''
  @property() reference: string = ''
  @property() viewToDisplay: string = 'EMPTY'
  @property() displayPlayerBottom: boolean = true
  @property() disableGallery: boolean = false
  @property() panoramaFailoverIndexes: string = ''
  @property() disabledGalleryStyles: any = { height: '100%' }
  @property() enableGalleryStyles: any = { height: '80%' }
  @property() shootingGallery: any = []
  @property() loading: boolean = false
  @property() data: any = {}
  @property() urlSelectedImage: string = ''
  @property() idSelectedImage: number = 0
  @property() shootingGalleryHotspots: any = []
  @property() galleryLength: number = 0
  @property() disableCounter: boolean = false
  @property() isFullScreen: boolean = false
  @property() resetScrollEvent: any = {}
  @property() disableAutoRotate: boolean = false
  @property() initialView: string = ''
  @property() infoBackgroundColor: string = ''
  @property() startScreen: string = 'none'
  @property() stamp: string = ''
  @property() addLabelOnButtons: boolean = false
  @property() buttonsColor: string = ''
  @property() buttonsHoverColor: string = ''
  @property() buttonsCheckedColor: string = ''
  @property() buttonExternalClose: string = ''
  @property() buttonExternalOpen: string = ''
  @property() buttonInternal: string = ''
  @property() buttonVideo: string = ''
  @property() buttonIconWidth: string = ''
  @property() buttonIconHeight: string = ''
  @property() width: string = ''
  @property() height: string = ''
  @property() backgroundColor: string = ''
  @property() externalImages: string = ''
  @property() language: string = ''
  @property() dms: string = ''
  @property() imageView: string = ''
  @property() buttonCertificateLabelPx: string = ''
  @property() buttonCertificateLabelPy: string = ''
  @property() disableCertificateButton: boolean = false
  @property() hotspotDefaultUrl: string | undefined = undefined
  @property() hotspotWarningUrl: string | undefined = undefined

  async connectedCallback() {
    super.connectedCallback()

    this.loading = true

    this.data = await getShootingData(this.reference, this.token, this.dms, this.imageView, this.externalImages)
    this.setContainerHeight()
    this.transformStandardGallery()
    this.initialiseGallery()
    this.panoramaFailover()
    this.initialViewToDisplay()
    this.toggleGallery()

    const lang = getLanguage(this.language)
    await initTranslation()
    await use(lang)

    this.loading = false
  }

  protected firstUpdated(changedProperties: any) {
    super.firstUpdated(changedProperties)
    const container = this.renderRoot.querySelector('.stampyt-container') as HTMLElement
    this.initContainerStyle(container, this.width, this.height, this.backgroundColor)
  }

  render() {
    return html`
      <div class="stampyt-container noselect">
        ${this.loading
          ? this.renderLoader()
          : html` <div
                id="player360"
                class="stampyt-player360"
                style=${styleMap(this.disableGallery ? this.disabledGalleryStyles : this.enableGalleryStyles)}
              >
                ${!this.disableCertificateButton ? this.renderCertificateButton() : html``}
                ${this.renderPlayerButtons()} ${this.componentToRender()}
              </div>
              ${this.renderPlayerBottom()}`}
      </div>
    `
  }

  componentToRender() {
    if (this.viewToDisplay === 'EMPTY') {
      return html`<player-empty></player-empty>`
    }

    if (this.viewToDisplay === 'PANORAMA_OUTSIDE_CLOSE') {
      return html`<player-360-outside
        .shooting=${this.data.panoramaOutsideClose}
        .disableAutoRotate="${this.disableAutoRotate}"
        .infoBackgroundColor="${this.infoBackgroundColor}"
        .loading="${this.loading}"
        .hotspotDefaultUrl="${this.hotspotDefaultUrl}"
        .hotspotWarningUrl="${this.hotspotWarningUrl}"
        .resetScrollEvent="${this.resetScrollEvent}"
        .startScreen="${this.startScreen}"
        .stamp="${this.stamp}"
        restamp
      ></player-360-outside>`
    }

    if (this.viewToDisplay === 'PANORAMA_OUTSIDE_OPEN') {
      return html`<player-360-outside
        .shooting=${this.data.panoramaOutsideOpen}
        .disableAutoRotate="${this.disableAutoRotate}"
        .infoBackgroundColor="${this.infoBackgroundColor}"
        .loading="${this.loading}"
        .hotspotDefaultUrl="${this.hotspotDefaultUrl}"
        .hotspotWarningUrl="${this.hotspotWarningUrl}"
        .resetScrollEvent="${this.resetScrollEvent}"
        .startScreen="${this.startScreen}"
        .stamp="${this.stamp}"
        restamp
      ></player-360-outside>`
    }

    if (this.viewToDisplay === 'PANORAMA_INSIDE') {
      return html`<player-360-inside
        .panoramaInside="${this.data.panoramaInside}"
        .disableAutoRotate="${this.disableAutoRotate}"
        .hotspotDefaultUrl="${this.hotspotDefaultUrl}"
        .hotspotWarningUrl="${this.hotspotWarningUrl}"
      >
      </player-360-inside>`
    }

    if (this.viewToDisplay === 'GALLERY') {
      return html`<player-gallery
        .shootingGallery="${this.shootingGallery}"
        .idImage="${this.idSelectedImage}"
        .hotspots="${this.shootingGalleryHotspots}"
        .disableCounter="${this.disableCounter}"
        .isFullScreen="${this.isFullScreen}"
        @child-property-changed="${this.handleIdSelectedImageChange}"
      >
      </player-gallery>`
    }

    if (this.viewToDisplay === 'VIDEO') {
      return this.renderVideoComponent()
    }

    return html``
  }

  renderCertificateButton() {
    return html` <player-certificate-label-button
      reference="${this.reference}"
      buttonCertificateLabelPx="${this.buttonCertificateLabelPx}"
      buttonCertificateLabelPy="${this.buttonCertificateLabelPy}"
      buttonsColor="${this.buttonsColor}"
      buttonsHoverColor="${this.buttonsHoverColor}"
    ></player-certificate-label-button>`
  }

  renderLoader() {
    return html` <player-loader .loading="${this.loading}"></player-loader>`
  }

  renderPlayerBottom() {
    if (this.displayPlayerBottom) {
      return html` <player-bottom
        .shootingGallery="${this.shootingGallery}"
        .idSelectedImage="${this.idSelectedImage}"
        .viewToDisplay="${this.viewToDisplay}"
        .resetScrollEvent="${this.resetScrollEvent}"
        @child-property-changed="${this.handleIdSelectedImageChange}"
      >
      </player-bottom>`
    }

    return html``
  }

  renderVideoComponent() {
    return html`<player-video .shootingVideo="${this.data.video}" .token="${this.token}"></player-video>`
  }

  renderPlayerButtons() {
    return html`
      <player-buttons
        isFullScreen="${this.isFullScreen}"
        viewToDisplay="${this.viewToDisplay}"
        .addLabelOnButtons="${this.addLabelOnButtons}"
        .shootings="${this.data}"
        buttonsColor="${this.buttonsColor}"
        buttonsCheckedColor="${this.buttonsCheckedColor}"
        buttonsHoverColor="${this.buttonsHoverColor}"
        buttonExternalClose="${this.buttonExternalClose}"
        buttonExternalOpen="${this.buttonExternalOpen}"
        buttonInternal="${this.buttonInternal}"
        buttonIconWidth="${this.buttonIconWidth}"
        buttonIconHeight="${this.buttonIconHeight}"
        buttonVideo="${this.buttonVideo}"
        .displayPlayerBottom="${this.displayPlayerBottom}"
        @child-property-changed="${this.handleTypeOfViewChange}"
      ></player-buttons>
    `
  }

  handleTypeOfViewChange(e: any) {
    this.viewToDisplay = e.detail.childProperty
  }

  handleIdSelectedImageChange(e: any) {
    this.idSelectedImage = e.detail.childProperty
    this.viewToDisplay = 'GALLERY'
  }

  initialiseGallery() {
    this.idSelectedImage = 0
  }

  panoramaFailover() {
    const shootingPanoramaOutsideCloseImages = this.data.panoramaOutsideClose.images
    const shootingGallery = this.shootingGallery
    if (shootingPanoramaOutsideCloseImages.length !== 0) return

    const panoramafailoverIndexesFormatted = this.formatPanoramaFailoverIndexes(this.panoramaFailoverIndexes)
    const panoramaFailoverExist = panoramafailoverIndexesFormatted.length > 0

    if (panoramaFailoverExist && shootingGallery.length > 0) {
      const data: any = []

      panoramafailoverIndexesFormatted.forEach((el) => {
        const val = shootingGallery[el]

        if (!val) return

        val.urlLow = convertUrlSelectedImage(val.url)
        data.push(val)
      })

      this.data.panoramaOutsideClose.images = data
      this.disableAutoRotate = true
    }
  }

  formatPanoramaFailoverIndexes(indexes: any) {
    const arrayIndexes = indexes.split(',')
    const verifiedArray = []

    for (let i = 0; i < arrayIndexes.length; i += 1) {
      let el = arrayIndexes[i]

      el && el.trim() !== '' ? (el = Number(el)) : (el = undefined)

      if (!isNaN(el)) {
        verifiedArray.push(el)
      }
    }

    return verifiedArray
  }

  initialViewToDisplay() {
    const panoramaFailoverIndexesFormated = this.formatPanoramaFailoverIndexes(this.panoramaFailoverIndexes)
    const panoramaFailoverExist = panoramaFailoverIndexesFormated.length > 0

    const shootingGallery = this.shootingGallery
    const shootingPanoramaOutsideCloseImages = this.data.panoramaOutsideClose.images
    const shootingPanoramaOutsideOpenImages = this.data.panoramaOutsideOpen.images
    const shootingPanoramaInsideImage = this.data.panoramaInside.url
    const shootingVideo = this.data.video

    const shootingGalleryExist = shootingGallery && shootingGallery.length > 0
    const shootingPanoramaOutsideCloseExist =
      shootingPanoramaOutsideCloseImages.length > 0 || (panoramaFailoverExist && shootingGalleryExist)
    const shootingPanoramaOutsideOpenExist = shootingPanoramaOutsideOpenImages.length > 0
    const shootingPanoramaInsideExist = shootingPanoramaInsideImage !== ''
    const shootingVideoExist = shootingVideo.reference && shootingVideo.reference !== ''

    const initialViewToDisplay = () => {
      switch (this.initialView) {
        case 'panorama_outside_close':
          if (shootingPanoramaOutsideCloseExist) return 'PANORAMA_OUTSIDE_CLOSE'
          break
        case 'panorama_outside_open':
          if (shootingPanoramaOutsideOpenExist) return 'PANORAMA_OUTSIDE_OPEN'
          break
        case 'panorama_inside':
          if (shootingPanoramaInsideExist) return 'PANORAMA_INSIDE'
          break
        case 'gallery':
          if (shootingGalleryExist && !this.disableGallery) return 'GALLERY'
          break
        case 'video':
          if (shootingVideoExist) return 'VIDEO'
          break
        default:
          return 'EMPTY'
      }

      return 'EMPTY'
    }

    let view = initialViewToDisplay()

    if (view === 'EMPTY') {
      view = this.displayViewInOrder(
        shootingPanoramaOutsideCloseExist,
        shootingPanoramaOutsideOpenExist,
        shootingPanoramaInsideExist,
        shootingGalleryExist,
        shootingVideoExist,
      )
    }

    this.viewToDisplay = view
  }

  transformStandardGallery() {
    const shootingGalleryStamped = this.data.standard.images.filter((i: IImage) => i.type === ImageType.stamped)
    const shootingGalleryOriginal = this.data.standard.images.filter((i: IImage) => i.type === ImageType.original)

    if (shootingGalleryStamped.length > 0) {
      this.shootingGallery = shootingGalleryStamped
    } else if (shootingGalleryOriginal.length > 0) {
      this.shootingGallery = shootingGalleryOriginal
    } else {
      this.shootingGallery = []
    }
  }

  // renvoie la vue a afficher si le user ne choisie pas de parametre par defaut
  displayViewInOrder(
    shootingPanoramaOutsideCloseExist: boolean,
    shootingPanoramaOutsideOpenExist: boolean,
    shootingPanoramaInsideExist: boolean,
    shootingGalleryExist: boolean,
    shootingVideoExist: boolean,
  ) {
    if (shootingVideoExist) return 'VIDEO'
    if (shootingPanoramaOutsideCloseExist) return 'PANORAMA_OUTSIDE_CLOSE'
    if (shootingPanoramaOutsideOpenExist) return 'PANORAMA_OUTSIDE_OPEN'
    if (shootingPanoramaInsideExist) return 'PANORAMA_INSIDE'
    if (shootingGalleryExist && !this.disableGallery) return 'GALLERY'
    return 'EMPTY'
  }

  initContainerStyle(container: HTMLElement, width: string, height: string, bgColor: string) {
    // style et taille du container
    if (bgColor) container.style.backgroundColor = bgColor
    if (width) container.style.width = width
    if (height) container.style.height = height
  }

  // définis la hauteur du container si aucune hauteur est spécifié
  setContainerHeight() {
    const el = this.renderRoot.querySelector('.stampyt-container') as HTMLElement

    if (!el) return
    const elH = el.getBoundingClientRect().height

    if (elH === 0) {
      el.style.height = '100%'
    }
  }

  toggleGallery() {
    if (this.disableGallery || this.shootingGallery.length === 0) {
      this.displayPlayerBottom = false
      this.disableGallery = true
    }
  }
}
