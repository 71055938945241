export default {
  panoramaOutsideCloseLabel: 'Panorama outside close',
  panoramaOutsideOpenLabel: 'Panorama outside open',
  panoramaInsideLabel: 'Panorama inside',
  videoLabel: 'Video',
  certificateButtonLabel: 'Stampyt Protect Certificat',
  constructPlayer: 'The player is under construction',
  oups: 'Ooops',
  error: 'Sorry, Something went wrong !',
};
