import { LitElement, html } from 'lit';
import { getStreamReadyStatus } from '../../services/api';
import { customElement, property } from 'lit/decorators.js';
import { playerStyles } from './styles';
import '../../utils/cloudflareStream.js';

@customElement('player-cloudflare')
export class PlayerCloudflare extends LitElement {
  static styles = [
    playerStyles,
  ];

  @property() reference: string = '';
  @property() token: string = '';
  @property() url: string = '';
  @property() error: boolean = false;
  @property() isValidUrl: boolean = false;

  firstUpdated(changedProperties: any) {
    super.update(changedProperties);

    this.getCloudflareUrl(this.reference);

    if (!this.isValidUrl) return;

    const container = this.cloudflareContainer();

    if (!container) return;
    // @ts-ignore
    const player = Stream(container);

    player.addEventListener('error', async () => {
      const readyToStream = await getStreamReadyStatus('CLOUDFLARE', this.reference, this.token);
      if (!(readyToStream && readyToStream.isReady)) this.error =  true;
    });
  }

  render() {
    return html`
      <div class="cloudflare-container">
        ${this.isValidUrl ?
          html`
            <iframe
                id="stampyt-cloudflare-player"
                title="stampyt cloudflare player"
                width="100%"
                height="100%"
                src="${this.url}"
                frameborder="0"
                allow="accelerometer;  gyroscope; autoplay; encrypted-media; picture-in-picture"
                allowfullscreen="true"
            ></iframe>` :
          html``
        }

        ${this.error ?
          html `
            <div class="error-message-block">
              <p class="error-message">La vidéo est en cours de chargement. Elle sera disponible dans quelques instants</p>
              <button class="button-reload-cloudflare" @click="${this.reload}">Actualiser</button>
            </div>
          ` : html ``
          }
      </div>`;
  }

  reload() {
    const container = this.cloudflareContainer();

    this.error = false;
    container.src = this.url;
  }

  cloudflareContainer() {
    return this.renderRoot.querySelector('#stampyt-cloudflare-player') as HTMLIFrameElement;
  }

  getCloudflareUrlWithReference(reference: string) {
    // tslint:disable-next-line:max-line-length
    return `https://embed.stampyt.io/cloudflare-iframe.videodelivery.net/${reference}&controls=true`;
  }

  useRegexCloudflareUrlValidation(input:string) {
    const regex = /https:\/\/embed\.stampyt\.io\/cloudflare-iframe\.videodelivery\.net\/[a-zA-Z\d]+&controls=true/i;
    return regex.test(input);
  }

  getCloudflareUrl(reference: string) {
    this.url = this.getCloudflareUrlWithReference(reference);
    this.isValidUrl = this.useRegexCloudflareUrlValidation(this.url);
  }
}
