import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { playerStyles } from './styles';
import { IHotspot, IPanoramaInside } from '../../models';
import '../playerLoading/player-loader';

// @ts-ignore
import * as PANOLENS from 'panolens';
// @ts-ignore
import { warningUrl, defaultUrl, imageContainer, imageElement, closeElement } from '../../utils/hotspot.js';

@customElement('player-360-inside')
export class Player360Inside extends LitElement {
  static styles = [
    playerStyles,
    css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }
      `,
  ];

  @property() panoramaInside: IPanoramaInside = {
    url: '',
    hotspots: [],
  };
  @property() disableAutoRotate: boolean = false;
  @property() loading: boolean = false;
  @property() isFullScreen: boolean = false;
  @property() urlSelectedHotspot: string = '';
  @property() image: any[] = [];
  @property() hotspots: any[] = [];
  @property() close: any;
  @property() closeFull: any;
  @property() eventRemove: any;
  @property() eventTouch: any;
  @property() eventTouch2: any;
  @property() hotspotDefaultUrl: string | undefined = undefined;
  @property() hotspotWarningUrl: string | undefined = undefined;

  constructor() {
    super();
    this.loading = true;
  }

  firstUpdated(changedProperties: any) {
    super.firstUpdated(changedProperties);

    let panorama: any;
    let viewer: any;

    // Container
    const container = this.renderRoot.querySelector('#scene') as HTMLElement;

    // IImage
    const panoramaInside = this.panoramaInside;
    const image = panoramaInside.url;
    const hotspots = panoramaInside.hotspots;

    const disableAutoRotate = this.disableAutoRotate;
    const hotspotDefaultUrl = this.hotspotDefaultUrl || defaultUrl;
    const hotspotWarningUrl = this.hotspotWarningUrl || warningUrl;

    this.close = this.touchControl.bind(this);
    this.closeFull = this.closeFullScreen.bind(this);

      // Panorama
    panorama = new PANOLENS.ImagePanorama(image);
    panorama.rotation.z += 1.7;
    panorama.rotation.x -= 1.5;

      // ajout chargement

    // Chargement
    const onProgressUpdate = (e: any) => {
      this.loading = true;

      const percentage = Math.floor(
          (e.progress.loaded / e.progress.total) * 100,
      );

      if (percentage >= 100) {
        this.loading = false;
      }
    };

    panorama.addEventListener('progress', onProgressUpdate.bind(this));

      // ajout hotspot
    this.addHotspots(hotspots, panorama, hotspotDefaultUrl, hotspotWarningUrl);

      // Viewer
    viewer = new PANOLENS.Viewer({
      container,
      cameraFov: 60,
      controlBar: false,
      autoHideInfospot: false,
    });

    viewer.add(panorama);
    viewer.getControl().autoRotate = !disableAutoRotate;
    viewer.getControl().autoRotateSpeed = -0.5;

    window.addEventListener('resize', () => {
      setTimeout(() => {
        const width = viewer.container.offsetWidth;
        const height = viewer.container.offsetHeight;
        viewer.onWindowResize(width, height);
      },         100);
    });

      // Event Listener touchstart
    container.addEventListener('click', stopAutoRotate, false);
    container.addEventListener('touchstart', stopAutoRotate, false);

    // Stop Auto Rotation
    function stopAutoRotate() {
      viewer.getControl().autoRotate = false;
    }
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
            ${this.loadingComponent()}
            ${this.fullScreenComponent()}
            <div id="scene" class="scene"></div>
        `;
  }

  get3Dcoordinates(
      xSource: number,
      ySource: number,
      widthBase: number,
      heightBase: number,
  ) {
    const imageWidth = 5376;
    const imageHeight = 2688;

    const proportionalXSource = (xSource * imageWidth) / widthBase;
    const proportionalYSource = (ySource * imageHeight) / heightBase;

    const theta =
        (-1 * (proportionalXSource - 4035) * 2 * Math.PI) / imageWidth -
        Math.PI / 2;
    const phi =
        ((proportionalYSource - 2688) * Math.PI) / imageHeight + Math.PI;

    const xDest = 500 * Math.sin(phi) * Math.cos(theta);
    const yDest = 500 * Math.cos(phi);
    const zDest = 500 * Math.sin(phi) * Math.sin(theta);

    return { x: xDest, y: yDest, z: zDest };
  }

  addHotspots(
      hotspots: IHotspot[],
      panorama: any,
      hotspotDefaultUrl: string | undefined,
      hotspotWarningUrl: string | undefined,
  ) {
    hotspots.forEach((hotspot: IHotspot) => {
      let infospot;

      switch (hotspot.type) {
        case 'pouce':
          infospot = new PANOLENS.Infospot(30, hotspotDefaultUrl);
          break;
        case 'warning':
          infospot = new PANOLENS.Infospot(30, hotspotWarningUrl);
          break;
        default:
          return;
      }

      const coordinates = this.get3Dcoordinates(
          hotspot.position.x,
          hotspot.position.y,
          hotspot.base.width,
          hotspot.base.height,
      );

      infospot.position.set(coordinates.x, coordinates.y, coordinates.z);
      infospot.hotspot = hotspot;
      infospot.addEventListener('click', () => { this.showImageHotspot(hotspot.image); });

      panorama.add(infospot);
    });
  }

  loadingComponent() {
    if (this.loading) {
      return html`<player-loader .loading="${this.loading}"></player-loader></div>`;
    }

    return html``;
  }

  fullScreenComponent() {
    if (this.isFullScreen) {
      return html `
                <div class="imgFullScreen" @click="${this.closeFullScreen}">
                    <button @click="${this.closeFullScreen}" class="close"></button>
                    <img src="${this.urlSelectedHotspot}"  alt=""/>
                </div>`;
    }

    return html``;
  }

  showImageHotspot(url: string) {
    this.eventRemove = this.removeFullScreen.bind(this);
    this.eventTouch = this.touchControl.bind(this);
    this.eventTouch2 = this.touchControl2.bind(this);

    const container = imageContainer();
    const img = imageElement(url);
    const close = closeElement();

    if (!document.fullscreenElement &&
        // @ts-ignore
        !document.webkitFullscreenElement &&
        // @ts-ignore
        !document.mozFullScreenElement &&
        // @ts-ignore
        !document.msFullscreenElement
    ) {
      container.appendChild(img);
      container.appendChild(close);
      document.body.appendChild(container);

      container.addEventListener('click', this.eventRemove);
      document.addEventListener('keydown', this.eventTouch2);
    } else {
      this.urlSelectedHotspot = url;
      this.displayFullScreen();
    }
  }

  removeFullScreen() {
    const container = document.getElementById('image-hotspot-fullscreen') as HTMLElement;

    if (!container) return;
    // @ts-ignore
    container.parentNode.removeChild(container);

    document.removeEventListener('keydown', this.eventTouch2);
  }

  touchControl2(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.removeFullScreen();
    }
  }

  closeFullScreen() {
    this.isFullScreen = false;
    document.removeEventListener('keydown', this.close);
  }

  touchControl(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.closeFullScreen();
    }
  }

  displayFullScreen() {
    this.isFullScreen = true;
    document.addEventListener('keydown', this.close);
    document.addEventListener('fullscreenchange', this.closeFull);
    document.addEventListener('webkitfullscreenchange', this.closeFull);
    document.addEventListener('mozfullscreenchange', this.closeFull);
    document.addEventListener('MSFullscreenChange', this.closeFull);
  }
}
