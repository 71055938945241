export function buttonsColorStyle(
  btnColorList: any[],
  btn: HTMLElement,
  container: HTMLElement | ShadowRoot) {

  const btnStatus = ['', '.checked', ':hover'];

  const style = document.createElement('style');
  let css = '';

  btnColorList.forEach((el, i) => {
    if (el) {
      const randomClassName = `stampyt-${Math.random().toString(36).substring(7)}`;

      btn.classList.add(randomClassName);
      css += `.${randomClassName}${btnStatus[i]}{ background-color:${el} !important;}`;
    }
  });

  if (btnColorList[0]) {
    css += `.button-360-label{ background-color:${btnColorList[0]}!important;}`;
  }

  if (css !== '') {
    // @ts-ignore
    style.styleSheet ? style.styleSheet.cssText =
      css : style.appendChild(document.createTextNode(css));
    container.appendChild(style);
  }
}

export function setButtonDimension(width: string, height: string, el: HTMLElement) {
  if (width) {
    const els = el.querySelectorAll('img, svg') as NodeListOf<HTMLElement>;
    els[0].style.width = width;
  }

  if (height) {
    const els = el.querySelectorAll('img, svg') as NodeListOf<HTMLElement>;
    els[0].style.height = height;
  }
}

// change l'image du bouton si l'utilisateur le définis
export function changeImageButton(btn: HTMLElement, icon: string) {
  const img = document.createElement('img');
  if (!btn) return;

  if (icon) {
    if (!btn.firstElementChild) return;

    const btnImg = btn.querySelector('.stampyt-button360') as HTMLElement;

    btnImg.removeChild(btn.querySelectorAll('img, svg')[0]);
    img.src = icon;
    btnImg.appendChild(img);
  }
}
