import { IShootingPhotos } from './shootingPhotos';
import { IImage, ImageType } from './image';
import { convertUrlSelectedImage } from '../utils/cloudflare';

interface IShooting360 extends IShootingPhotos {}

class Shooting360 implements IShooting360 {
  images: IImage[] = [];

  build(shooting: any, shootingHd?: any): IShooting360 {
    if (!shooting) {
      return new Shooting360();
    }

    try {
      const originals = shooting.originals.map((el:any) =>
          this.buildImage(el, shootingHd, ImageType.original));

      const stampeds = shooting.stampeds.map((el:any) =>
          this.buildImage(el, shootingHd, ImageType.stamped));

      return {
        images: [...originals, ...stampeds],
      } as IShooting360;

    } catch (e) {
      return new Shooting360();
    }
  }

  private buildImage(el: any, panoramaOutsideCloseHd: any, imageType: ImageType) {
    const urlHD = panoramaOutsideCloseHd &&
        panoramaOutsideCloseHd.originals.find((elHD: any) => elHD.order === el.order);

    const image: IImage = {
      type: imageType,
      url: el.url,
      urlHD: !!urlHD ? urlHD.url : undefined,
      urlLow: convertUrlSelectedImage(el.url),
      order: el.order,
      hotspots: el.hotspots,
    };
    return image;
  }
}

export { IShooting360, Shooting360 };
