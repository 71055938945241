export default {
  panoramaOutsideCloseLabel: 'Außenpanorama bei geschlossenen Türen',
  panoramaOutsideOpenLabel: 'Außenpanorama offene Türen',
  panoramaInsideLabel: 'Innenpanorama',
  videoLabel: 'Video',
  certificateButtonLabel: 'Stampyt Protect-Zertifikat',
  constructPlayer: 'Der Player befindet sich im Aufbau',
  oups: 'Ooops',
  error: 'Entschuldigung, Es ist ein Fehler aufgetreten',
};
