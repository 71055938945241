import { css } from 'lit';

export const playerStyles = css`
  .stampyt-player360 {
    position: absolute;
    width: 100%;
    height: 80%;
    top: 0;
  }

  .player360Container {
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
  }

  .loadedImagesContainer,
  .loadedImagesLowQualityContainer,
  .loadedHDImagesContainer {
    display: none;
  }

  .loadedImagesContainer > img,
  .loadedImagesLowQualityContainer > img,
  .loadedHDImagesContainer > img {
    position: absolute;
  }

  .player360Image {
    width: 100%;
  }

  .OF-contain {
    object-fit: contain;
    font-family: 'object-fit: contain';
  }

  .notSelectable {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .visible {
    z-index: 8;
  }

  .hidden {
    display: none;
  }

  .message-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hotspot {
    max-width: 30px;
  }

  .close {
    position: fixed;
    right: 40px;
    top: 20px;
    width: 33px;
    height: 33px;
    z-index: 99999999;
    opacity: .5;
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none !important;
  }

  .close:hover {
    opacity: 1;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #C5C5C5;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }

  .image-container {
    width: 100%;
    height: 100%;
  }

  .imgFullScreen {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999999;
  }

  .imgFullScreen img {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
    cursor: pointer;
  }

  .fullscreen_btn {
    cursor: pointer;
    z-index: 9999999;
    outline: none;
    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    position: relative;
    color: #434343;
    background-color: #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  }

  .fullscreen_btn > span::selection {
    color: none;
    background: none;
  }

  .fullscreen_btn:hover {
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
    background: #c0c0c2 radial-gradient(circle, transparent 1%, #c0c0c2 1%) center/15000%;
  }

  .fullscreen_btn svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 15px;
  }

  /* Ripple effect */

  .ripple {
    background-position: center;
    transition: background 0.8s;
  }

  /* .ripple:hover {
  \t\t\tbackground: #e5e8eb radial-gradient(circle, transparent 1%, #d0d4d8 1%) center/15000%;
  \t\t\t} */

  .ripple:active {
    background-color: #e5e8eb;
    background-size: 100%;
    transition: background 0s;
  }

  .nbPhotos {
    position: absolute;
    bottom: 9px;
    right: 9px;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 3px;
    padding-right: 9px;
    padding-left: 9px;
    border-radius: 6px;
    font-family: FiraSans-Bold, Fira Sans;
    color: #FFFFFF;
    font-weight: bold;
    z-index: 10;
  }

  .imageGallery,
  .imageGalleryInspect {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    object-fit: contain;
    font-family: 'object-fit: contain;';
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .object-fit-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .object-wrapper {
    width: 100%;
    max-width: 100%;
    display: block;
    position: absolute;
    padding-top: 56.25%;
    height: 1px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .object-wrapper img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  @media (min-aspect-ratio: 16/9) {
    .object-fit-container {
      text-align: center;
    }

    .object-wrapper {
      padding-top: 0;
      height: 100%;
      width: auto;
      display: inline-block;
      position: relative;
      background: #f00;
    }

    .object-wrapper img {
      max-width: 100%;
      display: block;
      width: auto;
      position: static;
      height: 100%;
      height: 100vh;
    }
  }

  .hotspotContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 15;
    pointer-events: none;
  }

  .hotspot {
    pointer-events: all;
  }

  .imgFullScreen > img {
    width: auto;
    height: auto;
    max-width: 85%;
    max-height: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999998;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .imgFullScreen > img::selection {
    color: none;
    background: none;
  }

  .rec {
    position: absolute;
    background-color: #434343;
    width: 100%;
    height: 140px;
    bottom: 0;
  }

  .innerRec {
    max-width: 1500px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding: 0 15px;
    margin: 0 auto;
  }
`;
