export default {
  panoramaOutsideCloseLabel: 'Panorama extérieur portes fermées',
  panoramaOutsideOpenLabel: 'Panorama extérieur portes ouvertes',
  panoramaInsideLabel: 'Panorama intérieur',
  videoLabel: 'Vidéo',
  certificateButtonLabel: 'Certificat Stampyt Protect',
  constructPlayer: 'Le player est en cours de construction',
  oups: 'Ooops',
  error: 'Désolé, Une erreur est survenue',
};
