import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';

import '../PlayerCloudflare/player-cloudflare';
import { translate } from 'lit-translate';

export class PlayerVideo extends LitElement {
  static styles = [
    css`
      .video-container {
        width: 100%;
        height: 100%;
      }

      .error-container {
        position: relative;
        height: 100%;
        background-color: #000000;
      }

      .error-container p {
        position: absolute;
        font-weight: 400;
        font-size: 23px;
        color: #ffffff;
        font-family: Roboto, Arial, sans-serif;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }`,
  ];

  @property() shootingVideoPartner: string = '';
  @property() error: boolean = false;
  @property() youtubeUrl: string = '';
  @property() reference: string = '';
  @property() scriptUrl: string = '';
  @property() stampytUrl: string = '';
  @property() token: string = '';
  @property() shootingVideo: any;

  connectedCallback() {
    super.connectedCallback();

    this.shootingVideoInit(this.shootingVideo);
  }

  render() {
    return html`
        <div class="video-container">
            ${
            this.error ?
              html `<div class="error-container">
                  <p>${translate('error')}</p>
              </div>`
            :
            html ``
            }

            ${ this.renderCloudflarePlayer() }
            ${ this.renderStampytPlayer() }
            ${ this.renderYoutubePlayer() }
        </div>
    `;
  }

  renderCloudflarePlayer() {
    if (this.shootingVideoPartner === 'CLOUDFLARE' && this.reference !== '') {
      return html `<player-cloudflare .reference="${this.reference}" token="${this.token}"></player-cloudflare>`;
    }

    return '';
  }
  renderStampytPlayer() {
    if (this.shootingVideoPartner === 'STAMPYT') {
      return html `
          <video class="video-container" controls>
              <source src="${this.stampytUrl}" type="video/mp4"/>
          </video>`;
    }

    return '';
  }

  renderYoutubePlayer() {
    if (this.shootingVideoPartner === 'YOUTUBE') {
      return html `
          <iframe
                  width="100%"
                  height="100%"
                  src="${this.youtubeUrl}"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
          >
          </iframe>`;
    }

    return '';
  }

  formatYoutubeUrl(reference: string) {
    const youtubeHostUrl = 'https://www.youtube.com/embed/';
    const queryParams = ['rel=0'];

    if (!reference || reference === '') return '';

    let newUrl = youtubeHostUrl + reference;

    queryParams.forEach((e, i) => {
      if (i === 0) {
        newUrl = `${newUrl}?${e}`;
      } else {
        newUrl = `${newUrl}&${e}`;
      }
    });

    return newUrl;
  }

  shootingVideoInit(data: any) {
    const { partner, reference } = data;

    if ((!reference || reference === '') && partner !== 'STAMPYT') {
      this.error = true;
    }

    if (partner !== 'YOUTUBE' && partner !== 'CLOUDFLARE' && partner !== 'STAMPYT') {
      this.error = true;
    } else {
      if (partner === 'YOUTUBE') {
        this.youtubeUrl = this.formatYoutubeUrl(reference);
      } else if (partner === 'CLOUDFLARE') {
        const url = `https://embed.cloudflarestream.com/embed/r4xu.fla9.latest.js?video=${reference}`;
        this.reference = reference;
        this.scriptUrl = url;
      } else if (partner === 'STAMPYT') {
        this.stampytUrl = data.url;
      } else {
        this.error = true;
      }
      this.shootingVideoPartner =  partner;
    }
  }
}

window.customElements.define('player-video', PlayerVideo);
