import { css } from 'lit';

export const playerStyles = css`
  .buttonCertificateLabel {
    outline: none;
    display: flex;
    align-items: center;
    z-index: 999;
    position: absolute;
    padding: 5px 10px;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

    .stampyt-button-color {
        background-color: var(--button360-color, rgba(102, 102, 102, 0.8));
    }

    .stampyt-button-color.checked {
        background-color: var(--button360-color-checked, #00aff0);
    }


    .stampyt-button-color:hover {
        background-color: var(--button360-color-hover, #008abd);
    }


  .buttonCertificateLabel svg {
    margin-right: 10px;
    fill: white;
  }
`;
